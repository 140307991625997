import {
  ActionReducerMapBuilder,
  PayloadAction,
  createAsyncThunk,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';

// API
import authApi from 'lib/api/auth';

// Constants
import { validationReqSchema } from 'features/register/constants';

// Types
import { CommonPayload } from '../../../../@types/action-payload';
import {
  EmailRegistrationState,
  IResponseMessage,
  EmailValidationPayload,
} from '../types';

// Actions
const CONTEXT = '@register/registerForm';
const actionType = {
  VALIDATE_FIELD: `${CONTEXT}/VALIDATE_FIELD`,
};

// Initial state
const initialState: EmailRegistrationState = {
  validations: {
    fullname: validationReqSchema,
    email: validationReqSchema,
    username: validationReqSchema,
    password: validationReqSchema,
  },
};

// Reducers (define how the state changes)
const reducers = {
  storeFieldValidation: (
    state: EmailRegistrationState,
    action: PayloadAction<{
      field: string;
      error?: string | null;
      message?: string | null;
    }>,
  ) => {
    const { field } = action.payload;
    state.validations[field].error = action.payload?.error ?? null;
    state.validations[field].isChecked = true;
    state.validations[field].isLoading = false;
    state.validations[field].message = action.payload?.message ?? null;
  },
  resetFieldValidation: (
    state: EmailRegistrationState,
    action: PayloadAction<{
      field: string;
    }>,
  ) => {
    const { field } = action.payload;
    state.validations[field].error = null;
    state.validations[field].isChecked = false;
    state.validations[field].isLoading = false;
    state.validations[field].message = null;
  },
  resetAllValidations: () => initialState,
};

// Effects (thunks / async operations that needs side effects)
export const effects = {
  // field validation
  validateField: createAsyncThunk<IResponseMessage, EmailValidationPayload>(
    actionType.VALIDATE_FIELD,
    async ({ field, value, tempKey }) => {
      try {
        if (!field || !value) {
          throw new Error(`The ${field} field is required`);
        }

        if (field === 'password' && value.length < 6) {
          throw new Error('Password harus terdiri dari minimal 6 karakter');
        }
        if (field === 'password' && value.length > 18) {
          throw new Error('Password harus terdiri dari maksimal 18 karakter');
        }

        const { data: message } = await authApi.validateRegisterForm(
          field,
          value,
          tempKey,
        );

        return { message };
      } catch (error) {
        return Promise.reject(error?.response?.data?.message || error.message);
      }
    },
  ),
};

// Selectors (getters)
export const selectors = createSelector(
  (state) => ({
    validations: state.register.emailRegister.validations,
  }),
  (email: EmailRegistrationState) => email,
);

// Extra reducers (handle actions that are not defined in this slice)
const extraReducers = (
  builder: ActionReducerMapBuilder<EmailRegistrationState>,
) => {
  builder
    .addCase(
      effects.validateField.pending,
      (
        draft: EmailRegistrationState,
        action: PayloadAction<CommonPayload, any, any>,
      ) => {
        const { field, value } = action.meta.arg;
        if (!draft.validations[field].error) {
          draft.validations[field].data = value;
          draft.validations[field].isChecked = false;
        }
        draft.validations[field].isLoading = true;
      },
    )
    .addCase(
      effects.validateField.fulfilled,
      (
        draft: EmailRegistrationState,
        action: PayloadAction<CommonPayload, any, any>,
      ) => {
        const { message } = action.payload;
        const { field } = action.meta.arg;
        draft.validations[field].error = null;
        draft.validations[field].isChecked = true;
        draft.validations[field].isLoading = false;
        draft.validations[field].message = message;
      },
    )
    .addCase(
      effects.validateField.rejected,
      (
        draft: EmailRegistrationState,
        action: PayloadAction<CommonPayload, string, any, any>,
      ) => {
        const {
          error: { message },
          meta: {
            arg: { field },
          },
        } = action;
        draft.validations[field].error = message;
        draft.validations[field].isChecked = true;
        draft.validations[field].isLoading = false;
        draft.validations[field].message = null;
      },
    );
};

const emailRegisterSlice = createSlice({
  name: 'emailRegister',
  initialState,
  reducers,
  extraReducers,
});

export default emailRegisterSlice;
