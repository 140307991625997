import { OtpChannel } from 'typings/auth';
import { CommonPayload } from '../../../@types/action-payload';

export enum VerifyPhoneErrorType {
  OtpLimit = 'OTP_LIMIT',
}

interface BaseUser {
  fullname: string;
  email: string;
  username: string;
}

export interface RegisterState extends BaseUser {
  accountid: string;
  accountkey: string;
  captchaToken: string;
  captchaVersion: number;
  channel: OtpChannel;
  code: number | string;
  country: string;
  currentForm: string;
  emailStatus: string;
  exchange: string;
  password: string;
  passwordConf: string;
  phoneNumber: string;
  supportId: string;
  tempKey: string;
  type: string;
  isLoading?: boolean;
  error?: Error | string | null;
}

export interface StoreProfile extends BaseUser {
  emailStatus: string;
  nextForm: string;
  password: string;
  passwordConf: string;
  tempKey: string;
}

export interface SocialIdentities extends BaseUser {
  accountid: string;
  accountkey: string;
}

export interface StoreSocialIdentities extends SocialIdentities {
  type: string;
  error?: string;
}

export interface StorePhoneNumber {
  country: { iso2: string };
  phoneNumber: string;
}

export interface StoreCaptchaToken {
  token: string;
}

export interface StoreEmailVerifData {
  emailStatus: string;
  tempKey: string;
}

export interface SavePhoneNumber {
  code: string;
  phoneNumber: string;
}

export interface IPayloadRegister extends BaseUser {
  password: string;
  passwordConf?: string;
}

export interface IPayloadCode {
  code: string;
}

export interface IPayloadVerifyPhone extends IPayloadCode {
  phoneNumber: string;
  channel: OtpChannel;
}

export interface IPayloadPhoneOTP {
  otp: string;
}

export interface IResponseMessage extends CommonPayload {
  message: string;
}

export interface IResponseRegister extends IResponseMessage {
  nextForm: string;
  root?: RegisterState;
  tempKey: string;
}

export interface IResponseEmailOTP extends IResponseMessage {
  root: RegisterState;
}

export interface IResponseVerifyPhone extends IResponseMessage {
  channel: OtpChannel;
  code: string;
  phoneNumber: string;
  root: RegisterState;
  tempKey: string;
}

export interface ValidationObject {
  data: string;
  error: string | null;
  isChecked: boolean;
  isLoading: boolean;
  message: string | null;
}

export interface EmailRegistrationState {
  validations: {
    fullname: ValidationObject;
    email: ValidationObject;
    username: ValidationObject;
    password: ValidationObject;
  };
}

export interface EmailValidationPayload {
  field: string;
  value: string;
  tempKey: string;
}

export interface FacebookPayload {
  accessToken: string;
  id: string;
  error: any;
  email: string;
  name: string;
}

export interface GooglePayload {
  profileObj: {
    email: string;
    name: string;
  };
  googleId: string;
  tokenId: string;
  error: any;
}
